:root {
  --primary: #ffffff;
  --secondary: #ff0000;
  --theme-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --theme-color: #171717;
  }
}

h1,
h2,
h3 {
  margin: 0;
}

body {
  margin: 0;
  color: #333;
  font-family: "Poppins", sans-serif;
  background: #ffffff;
}

html,
body {
  height: 100%;
  background-color: #ffffff;
}

div {
  display: block;
  unicode-bidi: isolate;
}

nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
  border-bottom: 0.1px solid #e5e5e5;
}

.search-bar {
  display: none;
  width: 97%;
  padding-left: 10px;
  font-size: 16px;
  color: #000000;
  height: 35px;
  background-color: rgb(236, 236, 236);
  border: 0px;
  border-radius: 10px;
  margin-top: 15px;
}

.search-bar:focus {
  outline: none;
  box-shadow: none;
}

.footer {
  display: none;
  width: 97%;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  gap: 4px;
}

.footer a {
  color: blue;
}

.footer a:visited {
  color: blue;
}

nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}

nav h1 {
  color: #000000;
}

.page {
  max-width: 550px;
  margin: 20px auto;
  padding: 10px;
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-content: flex-start;
  margin-bottom: 40px;
  column-gap: 10px;
  max-width: 530px;
}

.data-card {
  display: flex;
  flex-direction: column;
  width: 125px;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #f1f1f1;
  min-height: 142px;
  position: relative;
}

.data-card .rating {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #b4b1b1bd;
  color: #fff;
  border-radius: 12px;
  width: 40px;
  height: 0;
  padding: 20px 0;
  line-height: 0px;
  text-align: center;
  font-size: 12px;
}

img {
  width: 90px;
  object-fit: cover;
}

.img {
  border-radius: 10px;
  margin-top: 3px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 10px;
  height: 72px;
  object-fit: cover;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

h3 {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

p,
h4 {
  display: block;
  margin-block-start: 3px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  padding-left: 10px;
  padding-right: 10px;
}

p {
  color: #9f9f9f;
  font-size: 15px;
  margin-bottom: 5px;
}

h4 {
  color: red;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

h2 {
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
}

@media only screen and (min-width: 430px) and (max-width: 430px) {
  .data-grid {
    column-gap: 10px;
  }
  .page.home {
    margin-left: 8px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 390px) {
  .data-grid {
    column-gap: 5px;
  }
  .page.home {
    margin-left: 0px;
  }
  .data-card {
    width: 120px;
  }
  img {
    width: 110px;
  }
}

@media only screen and (min-width: 393px) and (max-width: 393px) {
  .data-grid {
    column-gap: 5px;
  }
  .page.home {
    margin-left: 1px;
  }
  .data-card {
    width: 120px;
  }
  img {
    width: 110px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 414px) {
  .data-grid {
    column-gap: 9px;
  }
  .page.home {
    margin-left: 1px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 375px) {
  .data-grid {
    column-gap: 5px;
  }
  .data-card {
    width: 118px;
  }

  img {
    width: 108.5px;
  }

  .page {
    padding-left: 6px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 428px) and (max-width: 428px) {
  .data-grid {
    column-gap: 10px;
  }
  .page.home {
    margin-left: 6px;
  }
}

@media only screen and (min-width: 402px) and (max-width: 402px) {
  .data-grid {
    column-gap: 3.5px;
  }
}

@media (prefers-color-scheme: dark) {
  body,
  div,
  nav {
    background: rgb(23, 23, 23);
    color: white !important;
  }
  h1,
  h2,
  h3 {
    color: white !important;
  }

  nav {
    border-bottom: 0.1px solid rgb(35, 35, 35);
  }

  .data-card {
    border: 1px solid rgb(39, 39, 39);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    background-color: rgb(39, 39, 39);
  }

  .img {
    background-color: #ffffff0d;
  }

  .search-bar {
    color: #ffffff !important;
    background-color: rgb(47, 47, 47) !important;
  }
}

h5 {
  position: absolute;
  right: 6px;
  top: 6px;
  text-align: right;
  z-index: 100;
  color: white;
  background-color: red;
  margin: AUTO;
  border-radius: 10px;
  padding: 1px;
  font-size: 11px;
  padding-left: 3px;
  padding-right: 3px;
}

.popup {
  position: fixed;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #ff5733;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  transition: bottom 1s ease-in-out;
  z-index: 9999;
  width: max-content;
}

@keyframes slide-up {
  from {
    bottom: -50px;
  }
  to {
    bottom: 10px;
  }
}

@keyframes slide-down {
  from {
    bottom: 10px;
  }
  to {
    bottom: -50px;
  }
}

.popup {
  animation: slide-up 1s forwards;
}

.popup.hide {
  animation: slide-down 0.5s forwards;
}

.loader {
  margin-top: 40%;
  width: 30px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ff0000;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
